<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="45%"
  >
    <div class="edit-main">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form
            :model="editForm"
            label-width="120px"
            :rules="rules"
            ref="editCopanyForm"
          >
            <el-form-item label="商户名称：" prop="busiName">
              <el-input
                size="small"
                v-model="editForm.busiName"
                placeholder="请输入商户名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="商户类别：" prop="busiType">
              <el-select
              class="block-select"
                v-model="editForm.busiType"
                size="small"
                placeholder="商户类别"
              >
                <el-option
                  v-for="item in busiType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!--            <el-form-item label="所属机构" prop="orgaId">-->
            <!--              <el-input size="small" v-model="editForm.orgaId" placeholder="请输入公司名称"></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="公司代码" prop="compCode">-->
            <!--              <el-input size="small" v-model="editForm.compCode" placeholder="请输入公司代码"></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item label="省:" prop="areaPid">
              <el-select
                class="block-select"
                size="small"
                filterable
                v-model="editForm.areaPid"
                placeholder="请选择所在省"
                @change="changeAreaParent"
              >
                <el-option
                  v-for="item in areaParentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市:" prop="areaId">
              <el-select
                class="block-select"
                size="small"
                filterable
                v-model="editForm.areaId"
                placeholder="请选择所在市"
                @change="changeArea"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址:" prop="busiAddr">
              <el-input
                size="small"
                v-model="editForm.busiAddr"
                placeholder="请输入地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="商户状态：" prop="state">
              <el-select
              class="block-select"
                v-model="editForm.state"
                size="small"
                placeholder="请选择商户状态"
              >
                <el-option
                  v-for="item in busiState"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="负  责  人：" prop="busiLinker">
              <el-input
              
                size="small"
                v-model="editForm.busiLinker"
                placeholder="请输负责人"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话：" prop="busiTel">
              <el-input
                size="small"
                v-model="editForm.busiTel"
                placeholder="请输联系电话"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <baidu-map
            :center="center"
            :zoom="zoom"
            class="mapbox"
            :scroll-wheel-zoom="true"
            @ready="handler"
            @click="getPoint"
          >
            <bm-marker
              :position="{
                lng: editForm.compLongitude,
                lat: editForm.compLatitude,
              }"
            ></bm-marker>
          </baidu-map>
        </el-col>
      </el-row>
    </div>
  </custom-dialog>
</template>

<script>
  import basic from "../../api/modules/basic";
import CustomDialog from "../../components/common/CustomDialog.vue";
//   import config from "../../../api/config";
import {busiState,busiType} from '../../config/dataStatus'
import {organizationCascader, phoneReg} from "../../utils/tool";
export default {
  name: "EditCopany",
  props: ["visible", "title", "item"],
  components: { CustomDialog },
  watch: {
    item(newVal, oldVal) {
      console.log('newVal',newVal);
      
      if (newVal.busiId) {
        this.editForm = newVal;
        this.editForm.busiType=Number(newVal.busiType)
        if (newVal.areaPid) {
          this.getAreaInfo(newVal.areaPid);
        }
      } else {
        this.editForm = {
          busiName: "",
          busiType: "",
          areaPid: "",
          areaId: "",
          busiAddr: "",
          state:'',
          busiLinker:'',
          busiTel:'',
          orgaId: 0,
        };
      }
      this.resetFields();
    },
  },
  data() {
    let checkMobile = (rule, value, callback) => {
      if (!phoneReg(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback()
      }
    };
    return {
      center: { lng: 116.404, lat: 39.915 },
      zoom: 15,
      editForm: {
      
      },
      rules: {
        busiName: [
          { required: true, message: "请输入商户名称", trigger: "blur" },
        ],
        busiType: [{ required: true, message: "请选择商户类别", trigger: "change" }],
        areaPid: [
          { required: true, message: "请选择省", trigger: "change" },
        ],
        areaId: [{ required: true, message: "请选择市", trigger: "change" }],
        busiAddr: [
          { required: true, message: "请输入地址", trigger: "change" },
        ],
        state: [{ required: true, message: "请选择商户状态", trigger: "change" }],
        busiLinker: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        busiTel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {validator: checkMobile, trigger: 'blur'}
        ],
      },
      options:[],
      areaParentList: [], // 省
      areaList: [], //市
      busiState:busiState,
      busiType:busiType
      // uploadUrl: config.uploadUrl,
    };
  },
  mounted() {
      this.getAreaInfo(0)
  },
  methods: {
    getAreaInfo(id) {
      let param = { pid: id };
      basic.getAreaList(param).then((res) => {
        console.log(res);
        if (id == 0) {
          this.areaParentList = res.data;
        } else {
          this.areaList = res.data;
        }
      });
    },
    changeAreaParent(id) {
      // console.log(id)
      this.editForm.areaId = "";
      this.getAreaInfo(id);
      // 地图的
      this.areaParentList.forEach((item) => {
        if (item.id === id) {
          this.center = item.name;
        }
      });
    },
    changeArea(id) {
      // 市切换
      // 地图的
      this.areaList.forEach((item) => {
        if (item.id === id && item.name != "市辖区") {
          this.center = item.name;
        }
      });
    },
    submit() {
      this.$refs.editCopanyForm.validate((valid) => {
        if (valid) {
          if (this.editForm.busiId) {
            // 编辑
            basic.updateBasebusiness(this.editForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("done");
            });
          } else {
            // 新增
            basic.addBasebusiness(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    colseDialog() {
      this.$emit("done");
    },
    handler({ BMap, map }) {
      let mapStyle = {
        style: "midnight",
      };
      map.setMapStyle(mapStyle);
    },
    getPoint(e) {
      //点击地图获取一些信息，
      // this.show = true;
      this.editForm.compLongitude = e.point.lng; //通过  e.point.lng获取经度
      this.editForm.compLatitude = e.point.lat; //通过  e.point.lat获取纬度
      let geocoder = new BMap.Geocoder(); //创建地址解析器的实例
      geocoder.getLocation(e.point, (res) => {
        this.editForm.busiAddr = res.address;
      });
    },
    resetFields() {
      if (this.$refs.editCopanyForm) {
        this.$refs.editCopanyForm.clearValidate();
        this.$refs.editCopanyForm.resetFields();
      }
    },
    uploadSuccess(val) {
      if (val.code == 200) {
        this.editForm.compIcon = val.message;
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style scoped lang="less">
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-top: 0px solid #eeeeee;
  border-radius: 4px;
}
.mapbox {
  height: 400px;
  width: 100%;
}
/deep/.el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  padding-top: 0px !important;
}
</style>
