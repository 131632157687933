<template>
    <div class="pageMain">
      <search-head :model="searchForm" @search="search" @reset="reset">
        <el-form :inline="true" :model="searchForm">
            <el-form-item label="商户名称">
          <el-input v-model="searchForm.busiName" size="small" placeholder="商户名称"></el-input>
        </el-form-item>
        <el-form-item label="商户类别">
          <el-select
              class="block-select"
                v-model="searchForm.busiType"
                size="small"
                placeholder="商户类别"
              >
                <el-option
                  v-for="item in busiType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
        </el-form-item>
      </el-form>
      </search-head>
      <custom-tables :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
        
          :total="total"
          title="商户信息">
        <template slot="btns">
          <el-button type="success" size="small" @click="addFrom" icon="el-icon-plus" class="addBtn">新增</el-button>
        </template>
        <el-table-column type="selection" align="center" width="55">
      </el-table-column>
        <el-table-column
            prop="busiName"
            label="商户名称"
            align="center"
          >
        </el-table-column>
        <el-table-column
            prop="busiType"
            align="center"
            label="类别"
          >
          <template slot-scope="scope">
                  <div v-if="scope.row.busiType==0">业主方</div>
                  <div v-if="scope.row.busiType==1">租赁方</div>
                  <div v-if="scope.row.busiType==2">施工方</div>
                  <div v-if="scope.row.busiType==3">监管方</div>

        </template>
        </el-table-column>
        <el-table-column
            prop="busiLinker"
            align="center"
            label="负责人">
        </el-table-column>
        <el-table-column
            prop="busiTel"
            align="center"
            label="联系电话">
        </el-table-column>
        <!-- <el-table-column
            prop="address"
            align="center"
            label="备注">
        </el-table-column> -->
        <el-table-column
            prop="createTime"
            align="center"
            label="添加时间">
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >
              <i class="el-icon-edit"></i>
            </span>
            <span
                @click="deleteItem(scope.row)"
                class="detail delete"
              >
              <i class="el-icon-delete"></i>
            </span>
            </template>
          </el-table-column>
      </custom-tables>
      <merchantEdit :visible.sync="showDialog" @close="colseDialog" :title="title" @done="doneDialog" :item="item"></merchantEdit>
     
    </div>
  </template>
  
  <script>
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import merchantEdit from "../../../components/basic/merchantEdit.vue";
  import {busiState,busiType} from '../../../config/dataStatus'
  import basic from '../../../api/modules/basic'
  export default {

    components: {merchantEdit, CustomTables, SearchHead},
    data() {
      return {
        tableData: [
      
        ],
        title:'',
        searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
        total:0,
        showDialog: false,
        headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      item:{},
      busiType:busiType
      }
    },
    mounted() {
      this.getList()
    },
    methods:{
      getList() {
        basic.getPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
      addFrom(){
        this.showDialog = true
        this.title='新增商户'
        this.item={}
      },
      colseDialog(){
        this.showDialog = false
      },
      doneDialog(){
        this.showDialog = false
        this.getList()
      },
      submit(){
        this.showDialog = false
      },
      detailItem(item){
        //详情
        this.showDialog = true
        this.title='编辑商户'
        this.item=item
    },
    deleteItem(item) {
      
      this.$confirm(`此操作将永久删除商户${item.busiName}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //{staffId:[item.staffId]}
        //  return
        basic.deleteBasebusiness(item.busiId).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      })
    },
    handleSelectionChange(val){
        console.log(val);
        
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  
    }
  }
  </script>
  
  <style scoped lang="less">
  .detail{
    color: #008f4d;
}
.delete{
    margin-left: 10px;
}
.addBtn{
    background-color:#008f4d;
}
  </style>